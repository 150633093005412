export default {
  default: () => import("/opt/render/project/src/layouts/default.vue").then(m => m.default || m),
  "discord-hq-action": () => import("/opt/render/project/src/layouts/discord-hq-action.vue").then(m => m.default || m),
  "discord-hq": () => import("/opt/render/project/src/layouts/discord-hq.vue").then(m => m.default || m),
  "discord-main": () => import("/opt/render/project/src/layouts/discord-main.vue").then(m => m.default || m),
  "discord-refinery-action": () => import("/opt/render/project/src/layouts/discord-refinery-action.vue").then(m => m.default || m),
  "discord-refinery": () => import("/opt/render/project/src/layouts/discord-refinery.vue").then(m => m.default || m),
  login: () => import("/opt/render/project/src/layouts/login.vue").then(m => m.default || m),
  main: () => import("/opt/render/project/src/layouts/main.vue").then(m => m.default || m),
  teambuilding: () => import("/opt/render/project/src/layouts/teambuilding.vue").then(m => m.default || m)
}