<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtErrorBoundary>
        <NuxtPage />

        <template #error="{ error }">
          <div class="inline-flex gap-x-2 align-center my-4">
            <code class="text-red-600">{{ error }}</code>
            <v-btn color="primary" @click="resetError(error)">
              reset
            </v-btn>
          </div>
        </template>
      </NuxtErrorBoundary>
    </NuxtLayout>
  </div>
</template>

<script setup>

const resetError = (error) => {
  console.log(error)
  error.value = null
}
</script>
