// plugins/vuetify.js
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { VCalendar } from 'vuetify/labs/VCalendar'
import { VNumberInput } from 'vuetify/labs/VNumberInput'

/**
 * color pallete from company logo
 *
 * background = #F9FCFB
 * surface = #FDFDFD
 *
 * blue = #04468A
 * primary = #444B83
 * secondry = #251965
 *
 *
 */
const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#F9FCFB',
    surface: '#FDFDFD',
    'surface-bright': '#FDFDFD',
    'surface-light': '#FDFDFD',
    'surface-variant': '#FDFDFD',
    'on-surface-variant': '#FDFDFD',
    primary: '#04468A',
    'primary-darken-1': '#04468A',
    secondary: '#444B83',
    'secondary-darken-1': '#444B83',
    tertiary: '#251965',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00'
  },
  variables: {
    'border-color': '#000000',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.6,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#000000'
  }
}

const adminTheme = {
  colors: {
    primary: '#f29f67',
    secondary: '#1e1e2c',
    background: '#F5F5F5',
    surface: '#FFFFFF',
    support1: '#3b8ff3',
    support2: '#34b1aa',
    support3: '#e0b50f',
    info: '#26C6DA',
    success: '#43A047',
    warning: '#FB8C00',
    error: '#E53935'
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: true,
    components:
    {
      VDateInput,
      VCalendar,
      VNumberInput,
    },
    directives,
    theme: {
      defaultTheme: 'adminTheme',
      themes: {
        myCustomLightTheme, adminTheme
      }
    }
  })

  nuxtApp.vueApp.use(vuetify)
})
