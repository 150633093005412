import { default as resetjFQ7YcJiyvMeta } from "/opt/render/project/src/pages/auth/reset.vue?macro=true";
import { default as signupLbQ58vUW69Meta } from "/opt/render/project/src/pages/auth/signup.vue?macro=true";
import { default as index5ZBXp2CKQrMeta } from "/opt/render/project/src/pages/automation/alarm/index.vue?macro=true";
import { default as indextRa1470TytMeta } from "/opt/render/project/src/pages/automation/boiler/index.vue?macro=true";
import { default as indexI7HH8PXI0ZMeta } from "/opt/render/project/src/pages/automation/dashboard/index.vue?macro=true";
import { default as indexywAmHtEACXMeta } from "/opt/render/project/src/pages/automation/report/index.vue?macro=true";
import { default as indexlaXyy47opIMeta } from "/opt/render/project/src/pages/automation/setting/index.vue?macro=true";
import { default as indexcztdaxnrJjMeta } from "/opt/render/project/src/pages/automation/trend/index.vue?macro=true";
import { default as indexTJXPs09zEhMeta } from "/opt/render/project/src/pages/homepage/index.vue?macro=true";
import { default as homepagezSrGUup8tLMeta } from "/opt/render/project/src/pages/homepage.vue?macro=true";
import { default as dashboardk0KK0J76YbMeta } from "/opt/render/project/src/pages/hq/dashboard.vue?macro=true";
import { default as dashboardV1Hn0cXJJWYTMeta } from "/opt/render/project/src/pages/hq/dashboardV1.vue?macro=true";
import { default as mainNbBy8a1g0DMeta } from "/opt/render/project/src/pages/hq/finance/main.vue?macro=true";
import { default as financeTsv3zbJohGMeta } from "/opt/render/project/src/pages/hq/finance.vue?macro=true";
import { default as index64sOarupc7Meta } from "/opt/render/project/src/pages/hq/index.vue?macro=true";
import { default as listOfT6TRDHsGMeta } from "/opt/render/project/src/pages/hq/management/list.vue?macro=true";
import { default as mainMQpYZn81T0Meta } from "/opt/render/project/src/pages/hq/management/main.vue?macro=true";
import { default as management5xdCcxEW1HMeta } from "/opt/render/project/src/pages/hq/management.vue?macro=true";
import { default as approvalacnk2BC01IMeta } from "/opt/render/project/src/pages/hq/marketing/contract/approval.vue?macro=true";
import { default as buyxSeUKGT9NMMeta } from "/opt/render/project/src/pages/hq/marketing/contract/buy.vue?macro=true";
import { default as listXzAlFkSXEXMeta } from "/opt/render/project/src/pages/hq/marketing/contract/list.vue?macro=true";
import { default as selleB8HuOYNZ1Meta } from "/opt/render/project/src/pages/hq/marketing/contract/sell.vue?macro=true";
import { default as mainFrkzRv8VDQMeta } from "/opt/render/project/src/pages/hq/marketing/main.vue?macro=true";
import { default as listcontactpersonLB6ShJsIouMeta } from "/opt/render/project/src/pages/hq/marketing/supplier/listcontactperson.vue?macro=true";
import { default as listmillnQA2igNBsOMeta } from "/opt/render/project/src/pages/hq/marketing/supplier/listmill.vue?macro=true";
import { default as listsuppliercusG8m4DSiMeta } from "/opt/render/project/src/pages/hq/marketing/supplier/listsupplier.vue?macro=true";
import { default as createHZXOPfFXswMeta } from "/opt/render/project/src/pages/hq/marketing/supplierV1/create.vue?macro=true";
import { default as edit3taTca00y8Meta } from "/opt/render/project/src/pages/hq/marketing/supplierV1/edit.vue?macro=true";
import { default as indexHlVkDKgSvpMeta } from "/opt/render/project/src/pages/hq/marketing/supplierV1/index.vue?macro=true";
import { default as marketinghP8Y6HdrJ1Meta } from "/opt/render/project/src/pages/hq/marketing.vue?macro=true";
import { default as listBPJbn8PklyMeta } from "/opt/render/project/src/pages/hq/product_request/list.vue?macro=true";
import { default as mainlmyG8d33OJMeta } from "/opt/render/project/src/pages/hq/product_request/main.vue?macro=true";
import { default as product_requestQdey7k2UFGMeta } from "/opt/render/project/src/pages/hq/product_request.vue?macro=true";
import { default as list0mxtUNLEG0Meta } from "/opt/render/project/src/pages/hq/purchaser/list.vue?macro=true";
import { default as purchaserzzlIczLlfQMeta } from "/opt/render/project/src/pages/hq/purchaser.vue?macro=true";
import { default as purchaserequestMie2Y2SwwtMeta } from "/opt/render/project/src/pages/hq/purchaserequest.vue?macro=true";
import { default as listZdGhkZpBRkMeta } from "/opt/render/project/src/pages/hq/store/list.vue?macro=true";
import { default as mainIhrFVYWTVlMeta } from "/opt/render/project/src/pages/hq/store/main.vue?macro=true";
import { default as storejv3fMKEOVBMeta } from "/opt/render/project/src/pages/hq/store.vue?macro=true";
import { default as approvalfLgP2WjFDtMeta } from "/opt/render/project/src/pages/hq/trader/contract/approval.vue?macro=true";
import { default as buyEFcVxD35l9Meta } from "/opt/render/project/src/pages/hq/trader/contract/buy.vue?macro=true";
import { default as listiwqOFKI6VKMeta } from "/opt/render/project/src/pages/hq/trader/contract/list.vue?macro=true";
import { default as sellLGBok3GhiGMeta } from "/opt/render/project/src/pages/hq/trader/contract/sell.vue?macro=true";
import { default as mainbKCwIg3it8Meta } from "/opt/render/project/src/pages/hq/trader/main.vue?macro=true";
import { default as trader0R8nHEymF4Meta } from "/opt/render/project/src/pages/hq/trader.vue?macro=true";
import { default as listKmPXkecjEBMeta } from "/opt/render/project/src/pages/hq/user_request/list.vue?macro=true";
import { default as mainR9LgHnLxDkMeta } from "/opt/render/project/src/pages/hq/user_request/main.vue?macro=true";
import { default as user_requestWpT1lAfFrBMeta } from "/opt/render/project/src/pages/hq/user_request.vue?macro=true";
import { default as hqIsNymc4HWpMeta } from "/opt/render/project/src/pages/hq.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as mainjVARbOapj3Meta } from "/opt/render/project/src/pages/main.vue?macro=true";
import { default as coss_receivinglaOQdLOipvMeta } from "/opt/render/project/src/pages/refinery/coss/buy/coss_receiving.vue?macro=true";
import { default as coss_buyergluS7NN9HgMeta } from "/opt/render/project/src/pages/refinery/coss/coss_buyer.vue?macro=true";
import { default as coss_new_ent_v2qo1OvbsflwMeta } from "/opt/render/project/src/pages/refinery/coss/coss_new_ent_v2.vue?macro=true";
import { default as coss_packaging_daily_report2V34aIYKXJbMeta } from "/opt/render/project/src/pages/refinery/coss/coss_packaging_daily_report2.vue?macro=true";
import { default as coss_summaryUF0rB6VetSMeta } from "/opt/render/project/src/pages/refinery/coss/coss_summary.vue?macro=true";
import { default as coss_supplierjlFeDLo1o1Meta } from "/opt/render/project/src/pages/refinery/coss/coss_supplier.vue?macro=true";
import { default as coss_buffer_tankGtwU2V5oGaMeta } from "/opt/render/project/src/pages/refinery/coss/packaging/coss_buffer_tank.vue?macro=true";
import { default as coss_packaging_daily_report0zViM3wrWTMeta } from "/opt/render/project/src/pages/refinery/coss/packaging/coss_packaging_daily_report.vue?macro=true";
import { default as coss_stock_losszigPoP2TomMeta } from "/opt/render/project/src/pages/refinery/coss/packaging/coss_stock_loss.vue?macro=true";
import { default as packaging_reportYJbA9NUhUDMeta } from "/opt/render/project/src/pages/refinery/coss/report/packaging_report.vue?macro=true";
import { default as sales_reportFtByP7ylJ9Meta } from "/opt/render/project/src/pages/refinery/coss/report/sales_report.vue?macro=true";
import { default as simulate_deliveryLFAyJrAhmIMeta } from "/opt/render/project/src/pages/refinery/coss/report/simulate_delivery.vue?macro=true";
import { default as stock_report_packedhcDPI2TNyKMeta } from "/opt/render/project/src/pages/refinery/coss/report/stock_report_packed.vue?macro=true";
import { default as stock_reportShz1xzGcFBMeta } from "/opt/render/project/src/pages/refinery/coss/report/stock_report.vue?macro=true";
import { default as coss_burstQ56XMmpgeqMeta } from "/opt/render/project/src/pages/refinery/coss/sale/coss_burst.vue?macro=true";
import { default as coss_buyerIYFkfrsu6JMeta } from "/opt/render/project/src/pages/refinery/coss/sale/coss_buyer.vue?macro=true";
import { default as coss_invoice_delivery_orderowAMvZJTt0Meta } from "/opt/render/project/src/pages/refinery/coss/sale/coss_invoice_delivery_order.vue?macro=true";
import { default as coss_listKexZUgjq2uMeta } from "/opt/render/project/src/pages/refinery/coss/sale/coss_list.vue?macro=true";
import { default as coss_new_entZ1FjesUYDHMeta } from "/opt/render/project/src/pages/refinery/coss/sale/coss_new_ent.vue?macro=true";
import { default as coss_scheduleb44pybVtJsMeta } from "/opt/render/project/src/pages/refinery/coss/sale/coss_schedule.vue?macro=true";
import { default as coss_sell_approvalhTA1KR7j4CMeta } from "/opt/render/project/src/pages/refinery/coss/sale/coss_sell_approval.vue?macro=true";
import { default as coss_selli8WBgZELDIMeta } from "/opt/render/project/src/pages/refinery/coss/sale/coss_sell.vue?macro=true";
import { default as cosss7DEeE54VaMeta } from "/opt/render/project/src/pages/refinery/coss.vue?macro=true";
import { default as dashboardNR6HwCN86WMeta } from "/opt/render/project/src/pages/refinery/dashboard.vue?macro=true";
import { default as listtR38e8q6BCMeta } from "/opt/render/project/src/pages/refinery/factorymanager/list.vue?macro=true";
import { default as factorymanagereibMAQXk0rMeta } from "/opt/render/project/src/pages/refinery/factorymanager.vue?macro=true";
import { default as indexpwoUfzwqeQMeta } from "/opt/render/project/src/pages/refinery/index.vue?macro=true";
import { default as shipmentNxg9nCUoRDMeta } from "/opt/render/project/src/pages/refinery/planner/shipment.vue?macro=true";
import { default as plannerLEXwtBISMEMeta } from "/opt/render/project/src/pages/refinery/planner.vue?macro=true";
import { default as listiEDPZvrXuHMeta } from "/opt/render/project/src/pages/refinery/purchaser/list.vue?macro=true";
import { default as purchaserOJdfHUm7wQMeta } from "/opt/render/project/src/pages/refinery/purchaser.vue?macro=true";
import { default as purchaserequestXhZfAdwM21Meta } from "/opt/render/project/src/pages/refinery/purchaserequest.vue?macro=true";
import { default as realtimezUtExs6AFJMeta } from "/opt/render/project/src/pages/refinery/realtime.vue?macro=true";
import { default as labltuWNtmOKjMeta } from "/opt/render/project/src/pages/refinery/report/lab.vue?macro=true";
import { default as maintenancew7vTpNnZuJMeta } from "/opt/render/project/src/pages/refinery/report/maintenance.vue?macro=true";
import { default as palmKernelby49BQUeYAMeta } from "/opt/render/project/src/pages/refinery/report/palmKernel.vue?macro=true";
import { default as productionxZvluvlchrMeta } from "/opt/render/project/src/pages/refinery/report/production.vue?macro=true";
import { default as runningHoursPQnqDyOhbkMeta } from "/opt/render/project/src/pages/refinery/report/runningHours.vue?macro=true";
import { default as stockMovementh8nsAZa6f6Meta } from "/opt/render/project/src/pages/refinery/report/stockMovement.vue?macro=true";
import { default as ullageGFQFPVOO8HMeta } from "/opt/render/project/src/pages/refinery/report/ullage.vue?macro=true";
import { default as utilitiesspPcmGzMQlMeta } from "/opt/render/project/src/pages/refinery/report/utilities.vue?macro=true";
import { default as weightBridgeGmNM5ikjgiMeta } from "/opt/render/project/src/pages/refinery/report/weightBridge.vue?macro=true";
import { default as reportGpC1UjWrm2Meta } from "/opt/render/project/src/pages/refinery/report.vue?macro=true";
import { default as testPlannerJKJOCvWYdRMeta } from "/opt/render/project/src/pages/refinery/testPlanner.vue?macro=true";
import { default as refineryxLeQzoP0XeMeta } from "/opt/render/project/src/pages/refinery.vue?macro=true";
import { default as logini9y9aNZvbLMeta } from "/opt/render/project/src/pages/supplier/auth/login.vue?macro=true";
import { default as signupveFJemRBq8Meta } from "/opt/render/project/src/pages/supplier/auth/signup.vue?macro=true";
import { default as supplierS7f7fyknU2Meta } from "/opt/render/project/src/pages/supplier.vue?macro=true";
import { default as indexBXUairRjh3Meta } from "/opt/render/project/src/pages/teambuilding/index.vue?macro=true";
import { default as lunchsPBFyEHLDEMeta } from "/opt/render/project/src/pages/teambuilding/lunch.vue?macro=true";
import { default as travel9LU9lPw2S0Meta } from "/opt/render/project/src/pages/teambuilding/travel.vue?macro=true";
import { default as teambuildingh86VsmxF43Meta } from "/opt/render/project/src/pages/teambuilding.vue?macro=true";
import { default as indexSvvelYYxZHMeta } from "/opt/render/project/src/pages/test/index.vue?macro=true";
export default [
  {
    name: "auth-reset",
    path: "/auth/reset",
    meta: resetjFQ7YcJiyvMeta || {},
    component: () => import("/opt/render/project/src/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupLbQ58vUW69Meta || {},
    component: () => import("/opt/render/project/src/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "automation-alarm",
    path: "/automation/alarm",
    meta: index5ZBXp2CKQrMeta || {},
    component: () => import("/opt/render/project/src/pages/automation/alarm/index.vue").then(m => m.default || m)
  },
  {
    name: "automation-boiler",
    path: "/automation/boiler",
    meta: indextRa1470TytMeta || {},
    component: () => import("/opt/render/project/src/pages/automation/boiler/index.vue").then(m => m.default || m)
  },
  {
    name: "automation-dashboard",
    path: "/automation/dashboard",
    meta: indexI7HH8PXI0ZMeta || {},
    component: () => import("/opt/render/project/src/pages/automation/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "automation-report",
    path: "/automation/report",
    meta: indexywAmHtEACXMeta || {},
    component: () => import("/opt/render/project/src/pages/automation/report/index.vue").then(m => m.default || m)
  },
  {
    name: "automation-setting",
    path: "/automation/setting",
    meta: indexlaXyy47opIMeta || {},
    component: () => import("/opt/render/project/src/pages/automation/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "automation-trend",
    path: "/automation/trend",
    meta: indexcztdaxnrJjMeta || {},
    component: () => import("/opt/render/project/src/pages/automation/trend/index.vue").then(m => m.default || m)
  },
  {
    name: homepagezSrGUup8tLMeta?.name,
    path: "/homepage",
    meta: homepagezSrGUup8tLMeta || {},
    component: () => import("/opt/render/project/src/pages/homepage.vue").then(m => m.default || m),
    children: [
  {
    name: "homepage",
    path: "",
    component: () => import("/opt/render/project/src/pages/homepage/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: hqIsNymc4HWpMeta?.name,
    path: "/hq",
    meta: hqIsNymc4HWpMeta || {},
    component: () => import("/opt/render/project/src/pages/hq.vue").then(m => m.default || m),
    children: [
  {
    name: "hq-dashboard",
    path: "dashboard",
    meta: dashboardk0KK0J76YbMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "hq-dashboardV1",
    path: "dashboardV1",
    meta: dashboardV1Hn0cXJJWYTMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/dashboardV1.vue").then(m => m.default || m)
  },
  {
    name: "hq-finance",
    path: "finance",
    meta: financeTsv3zbJohGMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/finance.vue").then(m => m.default || m),
    children: [
  {
    name: "hq-finance-main",
    path: "main",
    component: () => import("/opt/render/project/src/pages/hq/finance/main.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "hq",
    path: "",
    component: () => import("/opt/render/project/src/pages/hq/index.vue").then(m => m.default || m)
  },
  {
    name: "hq-management",
    path: "management",
    meta: management5xdCcxEW1HMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/management.vue").then(m => m.default || m),
    children: [
  {
    name: "hq-management-list",
    path: "list",
    meta: listOfT6TRDHsGMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/management/list.vue").then(m => m.default || m)
  },
  {
    name: "hq-management-main",
    path: "main",
    meta: mainMQpYZn81T0Meta || {},
    component: () => import("/opt/render/project/src/pages/hq/management/main.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "hq-marketing",
    path: "marketing",
    meta: marketinghP8Y6HdrJ1Meta || {},
    component: () => import("/opt/render/project/src/pages/hq/marketing.vue").then(m => m.default || m),
    children: [
  {
    name: "hq-marketing-contract-approval",
    path: "contract/approval",
    component: () => import("/opt/render/project/src/pages/hq/marketing/contract/approval.vue").then(m => m.default || m)
  },
  {
    name: "hq-marketing-contract-buy",
    path: "contract/buy",
    meta: buyxSeUKGT9NMMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/marketing/contract/buy.vue").then(m => m.default || m)
  },
  {
    name: "hq-marketing-contract-list",
    path: "contract/list",
    component: () => import("/opt/render/project/src/pages/hq/marketing/contract/list.vue").then(m => m.default || m)
  },
  {
    name: "hq-marketing-contract-sell",
    path: "contract/sell",
    meta: selleB8HuOYNZ1Meta || {},
    component: () => import("/opt/render/project/src/pages/hq/marketing/contract/sell.vue").then(m => m.default || m)
  },
  {
    name: "hq-marketing-main",
    path: "main",
    meta: mainFrkzRv8VDQMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/marketing/main.vue").then(m => m.default || m)
  },
  {
    name: "hq-marketing-supplier-listcontactperson",
    path: "supplier/listcontactperson",
    component: () => import("/opt/render/project/src/pages/hq/marketing/supplier/listcontactperson.vue").then(m => m.default || m)
  },
  {
    name: "hq-marketing-supplier-listmill",
    path: "supplier/listmill",
    component: () => import("/opt/render/project/src/pages/hq/marketing/supplier/listmill.vue").then(m => m.default || m)
  },
  {
    name: "hq-marketing-supplier-listsupplier",
    path: "supplier/listsupplier",
    component: () => import("/opt/render/project/src/pages/hq/marketing/supplier/listsupplier.vue").then(m => m.default || m)
  },
  {
    name: "hq-marketing-supplierV1-create",
    path: "supplierV1/create",
    component: () => import("/opt/render/project/src/pages/hq/marketing/supplierV1/create.vue").then(m => m.default || m)
  },
  {
    name: "hq-marketing-supplierV1-edit",
    path: "supplierV1/edit",
    component: () => import("/opt/render/project/src/pages/hq/marketing/supplierV1/edit.vue").then(m => m.default || m)
  },
  {
    name: "hq-marketing-supplierV1",
    path: "supplierV1",
    component: () => import("/opt/render/project/src/pages/hq/marketing/supplierV1/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "hq-product_request",
    path: "product_request",
    meta: product_requestQdey7k2UFGMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/product_request.vue").then(m => m.default || m),
    children: [
  {
    name: "hq-product_request-list",
    path: "list",
    meta: listBPJbn8PklyMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/product_request/list.vue").then(m => m.default || m)
  },
  {
    name: "hq-product_request-main",
    path: "main",
    meta: mainlmyG8d33OJMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/product_request/main.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "hq-purchaser",
    path: "purchaser",
    component: () => import("/opt/render/project/src/pages/hq/purchaser.vue").then(m => m.default || m),
    children: [
  {
    name: "hq-purchaser-list",
    path: "list",
    meta: list0mxtUNLEG0Meta || {},
    component: () => import("/opt/render/project/src/pages/hq/purchaser/list.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "hq-purchaserequest",
    path: "purchaserequest",
    meta: purchaserequestMie2Y2SwwtMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/purchaserequest.vue").then(m => m.default || m)
  },
  {
    name: "hq-store",
    path: "store",
    meta: storejv3fMKEOVBMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/store.vue").then(m => m.default || m),
    children: [
  {
    name: "hq-store-list",
    path: "list",
    meta: listZdGhkZpBRkMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/store/list.vue").then(m => m.default || m)
  },
  {
    name: "hq-store-main",
    path: "main",
    meta: mainIhrFVYWTVlMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/store/main.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "hq-trader",
    path: "trader",
    meta: trader0R8nHEymF4Meta || {},
    component: () => import("/opt/render/project/src/pages/hq/trader.vue").then(m => m.default || m),
    children: [
  {
    name: "hq-trader-contract-approval",
    path: "contract/approval",
    component: () => import("/opt/render/project/src/pages/hq/trader/contract/approval.vue").then(m => m.default || m)
  },
  {
    name: "hq-trader-contract-buy",
    path: "contract/buy",
    meta: buyEFcVxD35l9Meta || {},
    component: () => import("/opt/render/project/src/pages/hq/trader/contract/buy.vue").then(m => m.default || m)
  },
  {
    name: "hq-trader-contract-list",
    path: "contract/list",
    component: () => import("/opt/render/project/src/pages/hq/trader/contract/list.vue").then(m => m.default || m)
  },
  {
    name: "hq-trader-contract-sell",
    path: "contract/sell",
    meta: sellLGBok3GhiGMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/trader/contract/sell.vue").then(m => m.default || m)
  },
  {
    name: "hq-trader-main",
    path: "main",
    meta: mainbKCwIg3it8Meta || {},
    component: () => import("/opt/render/project/src/pages/hq/trader/main.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "hq-user_request",
    path: "user_request",
    meta: user_requestWpT1lAfFrBMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/user_request.vue").then(m => m.default || m),
    children: [
  {
    name: "hq-user_request-list",
    path: "list",
    meta: listKmPXkecjEBMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/user_request/list.vue").then(m => m.default || m)
  },
  {
    name: "hq-user_request-main",
    path: "main",
    meta: mainR9LgHnLxDkMeta || {},
    component: () => import("/opt/render/project/src/pages/hq/user_request/main.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexYy8SidDWaAMeta || {},
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "main",
    path: "/main",
    meta: mainjVARbOapj3Meta || {},
    component: () => import("/opt/render/project/src/pages/main.vue").then(m => m.default || m)
  },
  {
    name: refineryxLeQzoP0XeMeta?.name,
    path: "/refinery",
    meta: refineryxLeQzoP0XeMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery.vue").then(m => m.default || m),
    children: [
  {
    name: "refinery-coss",
    path: "coss",
    meta: cosss7DEeE54VaMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss.vue").then(m => m.default || m),
    children: [
  {
    name: "refinery-coss-buy-coss_receiving",
    path: "buy/coss_receiving",
    meta: coss_receivinglaOQdLOipvMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/buy/coss_receiving.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-coss_buyer",
    path: "coss_buyer",
    meta: coss_buyergluS7NN9HgMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/coss_buyer.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-coss_new_ent_v2",
    path: "coss_new_ent_v2",
    meta: coss_new_ent_v2qo1OvbsflwMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/coss_new_ent_v2.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-coss_packaging_daily_report2",
    path: "coss_packaging_daily_report2",
    meta: coss_packaging_daily_report2V34aIYKXJbMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/coss_packaging_daily_report2.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-coss_summary",
    path: "coss_summary",
    meta: coss_summaryUF0rB6VetSMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/coss_summary.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-coss_supplier",
    path: "coss_supplier",
    meta: coss_supplierjlFeDLo1o1Meta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/coss_supplier.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-packaging-coss_buffer_tank",
    path: "packaging/coss_buffer_tank",
    meta: coss_buffer_tankGtwU2V5oGaMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/packaging/coss_buffer_tank.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-packaging-coss_packaging_daily_report",
    path: "packaging/coss_packaging_daily_report",
    meta: coss_packaging_daily_report0zViM3wrWTMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/packaging/coss_packaging_daily_report.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-packaging-coss_stock_loss",
    path: "packaging/coss_stock_loss",
    meta: coss_stock_losszigPoP2TomMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/packaging/coss_stock_loss.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-report-packaging_report",
    path: "report/packaging_report",
    meta: packaging_reportYJbA9NUhUDMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/report/packaging_report.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-report-sales_report",
    path: "report/sales_report",
    meta: sales_reportFtByP7ylJ9Meta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/report/sales_report.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-report-simulate_delivery",
    path: "report/simulate_delivery",
    meta: simulate_deliveryLFAyJrAhmIMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/report/simulate_delivery.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-report-stock_report_packed",
    path: "report/stock_report_packed",
    meta: stock_report_packedhcDPI2TNyKMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/report/stock_report_packed.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-report-stock_report",
    path: "report/stock_report",
    meta: stock_reportShz1xzGcFBMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/report/stock_report.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-sale-coss_burst",
    path: "sale/coss_burst",
    meta: coss_burstQ56XMmpgeqMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/sale/coss_burst.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-sale-coss_buyer",
    path: "sale/coss_buyer",
    meta: coss_buyerIYFkfrsu6JMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/sale/coss_buyer.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-sale-coss_invoice_delivery_order",
    path: "sale/coss_invoice_delivery_order",
    meta: coss_invoice_delivery_orderowAMvZJTt0Meta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/sale/coss_invoice_delivery_order.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-sale-coss_list",
    path: "sale/coss_list",
    meta: coss_listKexZUgjq2uMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/sale/coss_list.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-sale-coss_new_ent",
    path: "sale/coss_new_ent",
    meta: coss_new_entZ1FjesUYDHMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/sale/coss_new_ent.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-sale-coss_schedule",
    path: "sale/coss_schedule",
    meta: coss_scheduleb44pybVtJsMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/sale/coss_schedule.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-sale-coss_sell_approval",
    path: "sale/coss_sell_approval",
    meta: coss_sell_approvalhTA1KR7j4CMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/sale/coss_sell_approval.vue").then(m => m.default || m)
  },
  {
    name: "refinery-coss-sale-coss_sell",
    path: "sale/coss_sell",
    meta: coss_selli8WBgZELDIMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/coss/sale/coss_sell.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "refinery-dashboard",
    path: "dashboard",
    meta: dashboardNR6HwCN86WMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "refinery-factorymanager",
    path: "factorymanager",
    component: () => import("/opt/render/project/src/pages/refinery/factorymanager.vue").then(m => m.default || m),
    children: [
  {
    name: "refinery-factorymanager-list",
    path: "list",
    meta: listtR38e8q6BCMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/factorymanager/list.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "refinery",
    path: "",
    component: () => import("/opt/render/project/src/pages/refinery/index.vue").then(m => m.default || m)
  },
  {
    name: "refinery-planner",
    path: "planner",
    component: () => import("/opt/render/project/src/pages/refinery/planner.vue").then(m => m.default || m),
    children: [
  {
    name: "refinery-planner-shipment",
    path: "shipment",
    meta: shipmentNxg9nCUoRDMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/planner/shipment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "refinery-purchaser",
    path: "purchaser",
    component: () => import("/opt/render/project/src/pages/refinery/purchaser.vue").then(m => m.default || m),
    children: [
  {
    name: "refinery-purchaser-list",
    path: "list",
    meta: listiEDPZvrXuHMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/purchaser/list.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "refinery-purchaserequest",
    path: "purchaserequest",
    meta: purchaserequestXhZfAdwM21Meta || {},
    component: () => import("/opt/render/project/src/pages/refinery/purchaserequest.vue").then(m => m.default || m)
  },
  {
    name: "refinery-realtime",
    path: "realtime",
    component: () => import("/opt/render/project/src/pages/refinery/realtime.vue").then(m => m.default || m)
  },
  {
    name: "refinery-report",
    path: "report",
    component: () => import("/opt/render/project/src/pages/refinery/report.vue").then(m => m.default || m),
    children: [
  {
    name: "refinery-report-lab",
    path: "lab",
    meta: labltuWNtmOKjMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/report/lab.vue").then(m => m.default || m)
  },
  {
    name: "refinery-report-maintenance",
    path: "maintenance",
    meta: maintenancew7vTpNnZuJMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/report/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "refinery-report-palmKernel",
    path: "palmKernel",
    meta: palmKernelby49BQUeYAMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/report/palmKernel.vue").then(m => m.default || m)
  },
  {
    name: "refinery-report-production",
    path: "production",
    meta: productionxZvluvlchrMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/report/production.vue").then(m => m.default || m)
  },
  {
    name: "refinery-report-runningHours",
    path: "runningHours",
    meta: runningHoursPQnqDyOhbkMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/report/runningHours.vue").then(m => m.default || m)
  },
  {
    name: "refinery-report-stockMovement",
    path: "stockMovement",
    meta: stockMovementh8nsAZa6f6Meta || {},
    component: () => import("/opt/render/project/src/pages/refinery/report/stockMovement.vue").then(m => m.default || m)
  },
  {
    name: "refinery-report-ullage",
    path: "ullage",
    meta: ullageGFQFPVOO8HMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/report/ullage.vue").then(m => m.default || m)
  },
  {
    name: "refinery-report-utilities",
    path: "utilities",
    meta: utilitiesspPcmGzMQlMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/report/utilities.vue").then(m => m.default || m)
  },
  {
    name: "refinery-report-weightBridge",
    path: "weightBridge",
    meta: weightBridgeGmNM5ikjgiMeta || {},
    component: () => import("/opt/render/project/src/pages/refinery/report/weightBridge.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "refinery-testPlanner",
    path: "testPlanner",
    component: () => import("/opt/render/project/src/pages/refinery/testPlanner.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "supplier",
    path: "/supplier",
    component: () => import("/opt/render/project/src/pages/supplier.vue").then(m => m.default || m),
    children: [
  {
    name: "supplier-auth-login",
    path: "auth/login",
    meta: logini9y9aNZvbLMeta || {},
    component: () => import("/opt/render/project/src/pages/supplier/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "supplier-auth-signup",
    path: "auth/signup",
    meta: signupveFJemRBq8Meta || {},
    component: () => import("/opt/render/project/src/pages/supplier/auth/signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: teambuildingh86VsmxF43Meta?.name,
    path: "/teambuilding",
    component: () => import("/opt/render/project/src/pages/teambuilding.vue").then(m => m.default || m),
    children: [
  {
    name: "teambuilding",
    path: "",
    meta: indexBXUairRjh3Meta || {},
    component: () => import("/opt/render/project/src/pages/teambuilding/index.vue").then(m => m.default || m)
  },
  {
    name: "teambuilding-lunch",
    path: "lunch",
    meta: lunchsPBFyEHLDEMeta || {},
    component: () => import("/opt/render/project/src/pages/teambuilding/lunch.vue").then(m => m.default || m)
  },
  {
    name: "teambuilding-travel",
    path: "travel",
    component: () => import("/opt/render/project/src/pages/teambuilding/travel.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "test",
    path: "/test",
    meta: indexSvvelYYxZHMeta || {},
    component: () => import("/opt/render/project/src/pages/test/index.vue").then(m => m.default || m)
  }
]