import { defineStore } from 'pinia'

export const useWebsiteStore = defineStore('websiteStore', {
  persist: true,
  state: () => ({
    main_header: 'Main',
    loading: true,
    snackbar: {
      color: '',
      icon: '',
      text: '',
      title: '',
      visible: false
    },
    user: null,
    menu: null,
    app_bar_title: ''
  }),
  actions: {
    setMainHeader (value: string) {
      this.main_header = value
    },
    setFeedback (visible:boolean, title:string, text:string, icon:string, color:string) {
      this.snackbar.visible = visible
      this.snackbar.title = title
      this.snackbar.text = text
      this.snackbar.icon = icon
      this.snackbar.color = color
    },
    getUser () {
      return this.user
    },
    setAppBarTitle (value: string) {
      this.app_bar_title = value
    }
  }
})
