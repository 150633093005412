export default defineNuxtRouteMiddleware((to) => {
  // skip middleware on server (first middleware execution)
  if (import.meta.server || import.meta.nitro) { } else if (import.meta.client || import.meta.browser) {
    const website = useWebsiteStore()
    website.setFeedback(false, '', '', '', '')
    if (to.path === '/' || to.path === '/auth/reset' || to.path.includes('/test') || to.path.includes('/teambuilding') || to.path.includes('/supplier')) { } else {
      try {
        const user = website.getUser()
        if (!user.username && !user.role.role) {
          throw createError({
            statusCode: 404,
            statusMessage: 'unauthorized access'
          })
        } else {

          if (user?.role?.permission_check === 'disabled') {
            return
          }
          const permission = useRoutePermission(to.path, user.role.role)
          if (permission === true) {

          } else {
            website.setFeedback(true, 'Error', 'you do not have permission to access this page', 'mdi-alert-circle-outline', 'error')
            return abortNavigation()
          }
        }
      } catch (error) {
        throw createError({
          statusCode: 404,
          statusMessage: 'Error:' + error
        })
      }
    }
  }
})
