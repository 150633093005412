export const useRoutePermission = (to: string, role: string): boolean => {
  if (to === '/main') { return true }
  if (to === '/') { return true }
  if (to === '') { return true }
  if (to.includes('refinery')) { return true }
  if (role === 'general-manager' && to === '/hq/finance/main') { return false }
  if (role === 'general-manager' && to === '/refinery/purchaserequest') { return false }
  if (role === 'general-manager' || role === 'eoiot-admin') { return true }
  if (role === 'factory-manager') { return to.includes('refinery') }

  if ((role.includes('coss-')) && to === '/refinery/coss/') { return true }
  if (role === 'coss-manager') { return to.includes('refinery/coss') }
  if (role === 'coss-sales' && to === '/refinery/coss/sale/coss_sell_approval') { return false }
  if (role === 'coss-sales') { return to.includes('refinery/coss/sale/') }
  if (role === 'coss-operator') { return to.includes('refinery/coss/packaging/') } else {
    const table = [
      {
        role: 'trader-buy',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/trader/contract/buy',
          '/hq/trader/contract/list',
          '/hq/purchaserequest',
          '/hq/purchaser/list'
        ]
      },
      {
        role: 'trader-sell',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/trader/contract/sell',
          '/hq/trader/contract/list',
          '/hq/purchaserequest',
          '/hq/purchaser/list'
        ]
      },
      {
        role: 'hod-trader-buy',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/trader/contract/buy',
          '/hq/trader/contract/list',
          '/hq/trader/contract/approval',
          '/hq/purchaserequest',
          '/hq/purchaser/list'
        ]
      },
      {
        role: 'hod-trader-sell',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/trader/contract/sell',
          '/hq/trader/contract/list',
          '/hq/trader/contract/approval',
          '/hq/purchaserequest',
          '/hq/purchaser/list'
        ]
      },
      {
        role: 'hod-trader-sell',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/trader/contract/sell',
          '/hq/trader/contract/list',
          '/hq/trader/contract/approval',
          '/hq/purchaserequest',
          '/hq/purchaser/list'
        ]
      },
      {
        role: 'purchaser-branch',
        permission: [
          '/refinery/',
          '/refinery/dashboard',
          '/refinery/purchaserequest',
          '/refinery/purchaser/list'
        ]
      },
      {
        role: 'senior-manager-trading',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/trader/contract/sell',
          '/hq/trader/contract/list',
          '/hq/trader/contract/approval',
          '/hq/purchaserequest',
          '/hq/purchaser/list'
        ]
      },
      {
        role: 'manager-marketing',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/marketing/contract/buy',
          '/hq/marketing/contract/list',
          '/hq/marketing/supplier/listsupplier',
          '/hq/marketing/supplier/listmill',
          '/hq/marketing/contract/approval',
          '/hq/purchaserequest',
          '/hq/purchaser/list'
        ]
      },

      {
        role: 'executive-marketing',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/purchaserequest',
          '/hq/purchaser/list',
          '/hq/marketing/contract/buy',
          '/hq/marketing/contract/list',
          '/hq/marketing/supplier/listsupplier',
          '/hq/marketing/supplier/listmill'
        ]
      },
      {
        role: 'executive-trading',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/purchaserequest',
          '/hq/purchaser/list',
          '/hq/trader/contract/list',
          '/hq/trader/contract/sell'

        ]
      },
      {
        role: 'senior-executive-marketing',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/purchaserequest',
          '/hq/purchaser/list',
          '/hq/marketing/contract/buy',
          '/hq/marketing/contract/list'
        ]
      },
      {
        role: 'senior-executive-trading',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/purchaserequest',
          '/hq/purchaser/list',
          '/hq/trader/contract/sell',
          '/hq/trader/contract/list'
        ]
      },
      {
        role: 'supervisor-marketing',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/purchaserequest',
          '/hq/purchaser/list',
          '/hq/trader/contract/list'
        ]
      },
      {
        role: 'supervisor-trading',
        permission: [
          '/hq/',
          '/hq/dashboard',
          '/hq/purchaserequest',
          '/hq/purchaser/list',
          '/hq/trader/contract/list'
        ]
      },
      {
        role: 'coss-sales',
        permission: [
          '/refinery/',
          '/refinery/coss/'
        ]
      },
      {
        role: 'coss-operator',
        permission: [
          '/refinery/',
          '/refinery/coss/'
        ]
      },
      {
        role: 'finance',
        permission: [
          '/homepage',
          '/hq/',
          '/hq/dashboard',
          '/hq/purchaserequest',
          '/hq/finance',
          '/hq/finance/main'
        ]
      }

    ]

    const rolePermissions =
      table.find(item => item.role === role.toLowerCase())?.permission || []

    return rolePermissions.includes(to)
  }
}
